<template>
  <div>
    <div
      :class="`${$classPrefix}title`"
      class="is-4 "
      v-html="$t('Components.AuthPinValidationForm.Main_Title')"
    ></div>
    <div
      class="has-margin-bottom"
      v-html="$t('Components.AuthPinValidationForm.Text_Explanation')"
    ></div>
    <Message
      v-if="pinError"
      :type="'is-danger'"
      class="has-margin-bottom"
    >{{
      $t('Components.AuthPinValidationForm.Message_ErrorPin')
    }}</Message>
    <TransitionExpand>
      <Message
        v-if="newCodeSent"
        :type="'is-success'"
        class="has-margin-bottom"
      >
        {{ $t('Components.AuthPinValidationForm.Message_NewPin') }}
      </Message>
    </TransitionExpand>
    <Form
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <RequiredField
        class="is-hidden"
        name="code"
        :value="code"
        @validated="onCodeValidated"
      />
      <div :class="`${$classPrefix}level has-margin-bottom input-codes is-mobile`">
        <div :class="`${$classPrefix}level-item`">
          <input
            type="text"
            ref="codePart1"
            @keyup="checkCodePart1"
            size="2"
            max="2"
            maxlength="2"
            name="codePart1"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `${$classPrefix}input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
        <div :class="`${$classPrefix}level-item`">
          <font-awesome-icon :icon="['fal', 'minus']" />
        </div>
        <div :class="`${$classPrefix}level-item`">
          <input
            type="text"
            ref="codePart2"
            @keyup="checkCodePart2"
            size="2"
            max="2"
            maxlength="2"
            name="codePart2"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `${$classPrefix}input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
        <div :class="`${$classPrefix}level-item`">
          <font-awesome-icon :icon="['fal', 'minus']" />
        </div>
        <div :class="`${$classPrefix}level-item`">
          <input
            type="text"
            ref="codePart3"
            @keyup="checkCodePart3"
            size="2"
            max="2"
            maxlength="2"
            name="codePart3"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `${$classPrefix}input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
      </div>
      <p v-if="errorCodeMessage">
        <span :class="`${$classPrefix}help is-danger`">
          {{ errorCodeMessage }}
        </span>
      </p>

      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`">
        </div>
        <div :class="`${$classPrefix}level-right`">
          <div :class="`${$classPrefix}level-item`">
            <button
              :class="[
                `${$classPrefix}button is-success`,
                {
                  'is-loading': isBusy,
                },
              ]"
              type="submit"
            >
              <span v-html="$t('Components.AuthPinValidationForm.Button_Request')"></span>
            </button>
          </div>
        </div>
      </div>
    </Form>
    <hr />
    <div class="">
      {{ $t('Components.AuthPinValidationForm.Text_CodenotWorking') }}
      <a
        @click="sendNewPin"
        v-html="$t('Components.AuthPinValidationForm.Link_CodenotWorking')"
      ></a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import Form from '@/components/UI/Form/BaseForm'
import Message from '@/components/UI/Message'
import RequiredField from '@/components/UI/Form/RequiredField'
import profileProvider from '../../providers/profile'
import TransitionExpand from '../TransitionExpand.vue'

export default {
  name: 'AuthPinValidationForm',
  components: {
    Form,
    Message,
    RequiredField,
    TransitionExpand,
  },

  props: {
    verificationKey: {
      type: String,
      required: true
    },
    email: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isProcessing: false,
      code: '',
      errorCodeMessage: '',
      checkCounter: 0,
      newCodeSent: false,
      pinError: false,
      mVerificationKey: this.verificationKey,
      messageTimer: null
    }
  },

  computed: {
    ...mapGetters('cartStore', ['isBusy']),
    ...mapState('cartStore', ['cart']),
    ...mapGetters('widgetStore', ['channelId']),
  },

  created() { },

  methods: {
    ...mapActions('authStore', ['loginByPin', 'SignOut']),

    handleSubmit({ formData }) {
      this.pinError = false
      this.newCodeSent = false
      this.checkCounter = this.checkCounter + 1

      return new Promise((resolve, reject) => {
        this.loginByPin({
          VerificationKey: this.mVerificationKey,
          Pin: formData.code
        })
          .then((response) => {
            if (response.status === 200) {
              this.$emit('callbackAction', {
                verifiedSuccess: true
              })
              resolve({ response: 'COMPLETED' })
            }
          })
          .catch(() => {
            this.pinError = true
            reject({ response: 'INVALID' })
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },

    onStatusChange({ status }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'INVALID':
          this.isProcessing = false
          break
        case 'COMPLETED':
          this.isProcessing = false
          break
      }
    },

    onCodeValidated() {
      this.errorCodeMessage = ''
      if (this.code === '') {
        this.errorCodeMessage = this.$t('App.Text_InputRequired')
      }
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    checkCodePart3() {
      this.updateCode()
    },

    updateCode() {
      let self = this
      let output = ''
      output += self.$refs.codePart1 ? self.$refs.codePart1.value.trim() : ''
      output += self.$refs.codePart2 ? self.$refs.codePart2.value.trim() : ''
      output += self.$refs.codePart3 ? self.$refs.codePart3.value.trim() : ''
      self.code = output
    },

    sendNewPin() {
      this.checkCounter = 0
      this.errorCodeMessage = ''

      profileProvider.fetchProfile({
        FirstName: '',
        LastName: '',
        Email: this.email,
        Language: this.$i18n.locale,
        ChannelId: this.channelId
      })
        .then(response => {
          this.mVerificationKey = response.data
          this.newCodeSent = true
          this.messageTimer && clearTimeout(this.messageTimer)
          this.messageTimer = setTimeout(() => {
            this.newCodeSent = false
            clearTimeout(this.messageTimer)
          }, 3000);
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style></style>
