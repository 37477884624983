<template>
  <div v-if="profile" class="InvoiceControls">
    <transition name="fade" mode="out-in">
      <div class="logoutControls" key="myCompanies">
        <span
          class="is-size-4 has-text-weight-bold"
          v-html="$t('App.InvoiceControls.Text_Welkom', { name: FirstName })"
        ></span>
        <a
          v-if="!userIsLoggedInOnMainSite"
          :class="[`${$classPrefix}button is-small is-info has-margin-left`]"
          @click="logoff"
          v-text="$t('General.Button_LogOff')"
        ></a>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getCookie } from '@/helpers/browserStorage'

export default {
  name: 'InvoiceControls',

  data() {
    return {
      userIsLoggedInOnMainSite: getCookie('lt') !== '',
    }
  },

  computed: {
    ...mapState('authStore', ['profile']),
    /**
     * The first name of the logged in user
     */
    FirstName() {
      return this.profile.FirstName
    },
  },
  methods: {
    ...mapActions('authStore', ['SignOut']),

    logoff() {
      this.SignOut()
    },
  },
}
</script>
