<template>
  <div>
    <div
      :class="`${$classPrefix}content has-margin-bottom-x2`"
      v-html="confirmText"
    ></div>

    <div
      v-if="reservation && hybrideMeeting"
      :class="`${$classPrefix}content`"
    >
      <div
        :class="`${$classPrefix}title is-3`"
        v-html="$t('Components.Confirmation.Channel_1.MeetingType_1.Header_HybrideMeeting')"
      ></div>
      <div>
        <p v-html="$t('Components.Confirmation.Channel_1.MeetingType_1.Text_HybrideMeeting', {
          videoUrl: hybrideMeeting.Link
        })"></p>
        <div
          :class="`${$classPrefix}title is-5`"
          v-html="$t('Components.Confirmation.Channel_1.MeetingType_1.SubHeader_HybrideMeeting')"
        ></div>
        <Message>
          <div v-html="$t('Components.Confirmation.Channel_1.MeetingType_1.Message_HybrideMeeting')"></div>
        </Message>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Message from '../UI/Message.vue'
export default {
  name: 'confirmation-channel_1',

  components: { Message },

  computed: {
    ...mapState('cartStore', ['reservation']),
    ...mapGetters('cartStore', ['Cart']),
    ...mapGetters('authStore', ['profile']),
    ...mapGetters('widgetStore', ['channel']),
    ...mapGetters('locationmanagers', ['activeManager']),
    ...mapGetters('availabilityStore', ['selectedLocation']),
    confirmText() {
      if (this.profile && this.activeManager && this.selectedLocation && this.Cart) {
        return this.$t('Components.Confirmation.Channel_1.MeetingType_1.Text_confirmation', {
          firstName: this.profile.FirstName,
          phone: this.activeManager.Phone || this.selectedLocation.Phone,
          email: this.activeManager.Email || this.selectedLocation.Email,
          hostName: this.activeManager.Name,
          locationName: this.selectedLocation.Name,
          date: this.$options.filters.dateFormat(this.Cart.StartDate, this.$i18n.locale),
          startTime: this.$options.filters.minutesToTime(this.Cart.StartMinutes)
        })
      }
      return ''
    },

    hybrideMeeting() {
      return this.reservation.ExternalLinks.find(el => el.LinkType === 'VideoCall') ?? null
    }

    // hybrideMeetingText() {
    //   return this.$t('Components.Confirmation.Channel_1.MeetingType_1.Text_HybrideMeeting')
    //     .replace(/##videoUrl##/g, 'https://www.seats2meet.com')
    //     .replace(/##messageCssClass##/g, `${this.$classPrefix}message is-info`)
    //     .replace(/##messageBodyCssClass##/g, `${this.$classPrefix}message-body`)
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>