<template>
  <div class="account-flow" v-if="cart && !hasOverlap && !isLoading">
    <transition name="fade" mode="out-in">
      <AuthFlow key="notLoggedIn" v-if="!profile" />

      <div key="readyLoading" v-else>
        <invoice-address-controller ref="invoiceController" class="mb-6" />

        <template v-if="cart.MeetingTypeId === 2 && showBookingDetailsStep">
          <BookerExpertiseForm class="mb-6" />
        </template>

        <div class="pb-6" :class="{ 'has-billing-errors': !billingDataReady }">
          <div class="overlay" v-if="!billingDataReady"></div>
          <div
            :class="`${$classPrefix}columns-finalize ${$classPrefix}columns is-multiline`"
          >
            <div :class="`${$classPrefix}column`">
              <VoucherForm v-if="showVoucherForm" />
            </div>
            <div :class="`${$classPrefix}column is-full-mobile`">
              <div class="mb-5">
                <BookingTerms
                  :showOnPage="false"
                  name="terms"
                  :class="`${$classPrefix}checkbox`"
                />
              </div>
              <NextButton
                :onClickCustomFunc="createBooking"
                :class="[
                  `${$classPrefix}button is-medium is-success is-fullwidth`,
                  { 'is-loading': isBusy },
                ]"
                v-text="
                  $t('Components.InvoiceDetails.InvoiceAddressForm.Button_Save')
                "
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AuthFlow from "@/components/Auth/AuthFlow";
import VoucherForm from "@/components/ReservationDetails/VoucherForm";
import BookingTerms from "@/components/Terms/BookingTerms";
import NextButton from "@/components/Navigation/NextButton";
import BookerExpertiseForm from "@/components/ReservationDetails/BookerExpertiseForm";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import InvoiceAddressController from "./InvoiceAddressController.vue";

export default {
  name: "AccountForm",
  components: {
    AuthFlow,
    VoucherForm,
    BookingTerms,
    NextButton,
    BookerExpertiseForm,
    InvoiceAddressController,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("availabilityStore", ["hasOverlap", "selectedLocationId"]),
    ...mapState("billingStore", [
      "billingInfo",
      "billingFormStateIsPreview",
      "billingIsvalid",
    ]),
    ...mapState("cartStore", ["cart"]),
    ...mapState("authStore", ["profile"]),
    ...mapGetters("widgetStore", ["channelId", "showBookingDetailsStep"]),
    ...mapGetters("authStore", ["isLoading", "isLoggedIn", "getProfileToken"]),
    ...mapGetters("cartStore", ["isBusy"]),
    ...mapGetters("searchStore", ["LastStep"]),

    billingDataReady() {
      return (
        this.cart.MeetingTypeId === 2 ||
        (this.cart.MeetingTypeId !== 2 && this.billingFormStateIsPreview)
      );
    },

    requiredFieldValid() {
      return this.cart.CartTerms !== null
        ? this.cart.CartTerms.Accepted
        : false;
    },

    showVoucherForm() {
      return (
        this.cart.MeetingTypeId !== 2 ||
        (this.cart.MeetingTypeId === 2 &&
          (this.cart.TotalExclTax || this.cart.VoucherId))
      );
    },
  },

  watch: {
    getProfileToken(val) {
      if (!this.isLoading && val) {
        this.getProfile();
      }
    },

    requiredFieldValid: {
      immediate: true,
      handler: function (isValid) {
        isValid ? this.enableNextStep() : this.disableNextStep();
      },
    },
  },

  created() {
    if (!this.hasInvoiceAddress || !this.this.cart.CartTerms.Accepted) {
      this.disableNextStep();
    }

    if (!this.profile && this.isLoggedIn) {
      this.getProfile();
    }
  },

  beforeDestroy() {
    this.enableNextStep();
  },

  methods: {
    ...mapMutations("searchStore", ["disableNextStep", "enableNextStep"]),
    ...mapActions("authStore", ["getProfile"]),
    ...mapActions("cartStore", ["setInvoiceAddress"]),
    ...mapActions("searchStore", ["goToStep"]),

    async createBooking() {
      if (!this.cart.CartTerms.Accepted) {
        return;
      }

      const FORM_RESPONSE = await this.$refs.invoiceController.processFormData(
        false
      );

      if (FORM_RESPONSE.isValid) {
        this.goToStep({ step: this.LastStep + 1 });
      }
    },
  },
};
</script>
