<template>
  <div>
    <div
      :class="`${$classPrefix}content has-margin-bottom-x2`"
      v-html="confirmText"
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'confirmation-channel_1031',

  computed: {
    ...mapState('cartStore', ['reservation']),
    ...mapGetters('cartStore', ['Cart']),
    ...mapGetters('authStore', ['profile']),
    ...mapGetters('widgetStore', ['channel']),
    ...mapGetters('locationmanagers', ['activeManager']),
    ...mapGetters('availabilityStore', ['selectedLocation']),

    confirmText() {
      if (this.profile && this.activeManager && this.selectedLocation && this.Cart) {
        return this.$t('Components.Confirmation.Channel_1031.MeetingType_1.Text_confirmation', {
          firstName: this.profile.FirstName,
          phone: this.activeManager.Phone || this.selectedLocation.Phone,
          email: this.activeManager.Email || this.selectedLocation.Email,
          hostName: this.activeManager.Name,
          locationName: this.selectedLocation.Name,
          date: this.$options.filters.dateFormat(this.Cart.StartDate, this.$i18n.locale),
          startTime: this.$options.filters.minutesToTime(this.Cart.StartMinutes)
        })
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
</style>