<template>
  <transition-group
    name="fade"
    mode="out-in"
  >
    <div
      key="dateTimePickerBackground"
      :class="`${$classPrefix}widget ${$classPrefix}dpPopup-background`"
    ></div>
    <div
      key="dateTimePicker"
      :ref="refName"
      :class="`${$classPrefix}widget ${$classPrefix}dpPopup`"
    >
      <div :class="`${$classPrefix}card`">
        <AirbnbStyleDatepicker
          :triggerElementId="target"
          v-bind="buildBindings()"
          v-on="datePickerListeners()"
        />
        <div
          v-if="mode !== 'range'"
          :class="`${$classPrefix}dpTimeSelectors`"
        >
          <strong>
            <i18n path="General.Text_Time"></i18n>:
          </strong>
          <div :class="[`${$classPrefix}select`, { 'is-loading': isLoading }]">
            <select
              class="is-shadowless"
              :disabled="isLoading"
              v-model="dateTime.startMinutes"
            >
              <option
                v-for="(option, index) in timeslots"
                :key="index"
                :value="option"
                :selected="option === dateTime.startMinutes"
              >{{ option | minutesToTime }}</option>
            </select>
          </div>
          <span :class="`${$classPrefix}icon`">
            <font-awesome-icon :icon="['fal', 'minus']" />
          </span>
          <div :class="[`${$classPrefix}select`, { 'is-loading': isLoading }]">
            <select
              class="is-shadowless"
              :disabled="isLoading"
              v-model="dateTime.endMinutes"
            >
              <option
                v-for="(option, index) in timeslots"
                :key="index"
                :value="option"
                :selected="option === dateTime.endMinutes"
              >{{ option | minutesToTime }}</option>
            </select>
          </div>
        </div>
        <footer
          v-if="confirmChoice"
          :class="`${$classPrefix}card-footer`"
        >
          <a
            @click="hideDatePicker()"
            :class="`${$classPrefix}card-footer-item`"
            v-text="$t('General.Text_Cancel')"
          ></a>
          <a
            @click="applyNewDates()"
            :disabled="isLoading"
            :class="[{ 'has-text-grey is-disabled': isLoading }, `${$classPrefix}card-footer-item has-text-weight-bold`]"
            v-text="$t('General.Text_Apply')"
          ></a>
        </footer>
      </div>
    </div>
  </transition-group>
</template>

<script>
import Vue from 'vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import EventBus from '@/eventbus/EventBus'
import { mapActions, mapMutations, mapState } from 'vuex'
const datepickerOptions = {}
Vue.use(AirbnbStyleDatepicker, datepickerOptions)

export default {
  name: 'date-picker-popup',

  props: {
    target: {
      default: null,
      required: true,
    },

    minDate: {
      type: String,
      default: function() {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },

    dates: {
      type: [Date, String, Object],
      default: function() {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },

    startMinutes: {
      type: Number,
      default: 540,
    },
    endMinutes: {
      type: Number,
      default: 960,
    },

    confirmChoice: {
      type: Boolean,
      default: true,
    },

    mode: {
      type: String,
      default: 'single',
    },

    use24HourTimeSlot: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refName: `popup${this.target}`,
      refDatePicker: null,
      ready: false,
      trigger: document.getElementById(this.target),
      readyCounter: 0,
      isLoading: false,
      dateTime: {
        dates: this.$objectHelper.cleanSource(this.dates),
        startMinutes: this.startMinutes,
        endMinutes: this.endMinutes,
      },
      openingHoursData: null,
    }
  },

  computed: {
    ...mapState('searchStore', ['search']),

    timeslots() {
      return !this.use24HourTimeSlot
        ? this.$store.state.widgetStore.timeslots
        : this.build24HoursTimeslots()
    },

    datePickerReady() {
      if (
        (this.readyCounter >= 1 && this.mode === 'single') ||
        (this.readyCounter >= 2 && this.mode === 'range')
      ) {
        return true
      } else {
        return false
      }
    },
  },

  mounted() {
    let self = this

    // Append modal to body
    document.body.appendChild(this.$el)
    self.ready = true
    this.getOpeningHours()
    let t = setTimeout(() => {
      document.addEventListener('scroll', self.scrollHandler, true)
      EventBus.$on('windowResizeListener', self.scrollHandler)
      self.refDatePicker = self.$refs[self.refName]
      self.scrollHandler()
      clearTimeout(t)
    }, 50)
  },

  beforeDestroy() {
    this.resetBeforeDestroy()
  },

  methods: {
    ...mapMutations('widgetStore', ['setOpeningHours']),
    ...mapActions('widgetStore', [
      'getOpeningHoursData',
      'processOpeningHours',
    ]),

    build24HoursTimeslots() {
      let timeslots = []
      for (var i = 0; i <= 1440; i = i + 30) {
        timeslots.push(i)
      }
      return timeslots
    },

    resetBeforeDestroy() {
      // Remove listeners
      removeEventListener('scroll', this.scrollHandler)
      EventBus.$off('windowResizeListener', this.scrollHandler)

      // Hide date picker
      this.ready = false

      // Remove node from body
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
    },

    /**
     * Date time popup actions
     */
    hideDatePicker() {
      this.resetBeforeDestroy()
      this.$emit('hide')
    },

    /**
     * Update date picker location on scroll
     */
    scrollHandler() {
      // Check if datePickerRects is not undefined. After second time opening the date picker this issue occuers
      const viewPortWidth = window.innerWidth
      const viewPortHeight = window.innerHeight
      const triggerRects = this.trigger.getBoundingClientRect()
      const datePickerRects = this.refDatePicker.getBoundingClientRect()
      const vSpaceGap = 5
      const hSpaceGap = 15

      let canBeAddedAbove = (triggerRects.top - vSpaceGap - datePickerRects.height) > 15
      let canBeAddedBelow = viewPortHeight - (triggerRects.top + triggerRects.height + vSpaceGap + datePickerRects.height) > 15

      // Position datepicker below the input
      this.refDatePicker.style.top = `${triggerRects.top +
        triggerRects.height +
        vSpaceGap}px`

      if (!canBeAddedAbove && canBeAddedBelow) {
        // Position datepicker below the input
        this.refDatePicker.style.top = `${triggerRects.top +
          triggerRects.height +
          vSpaceGap}px`
      }

      if (canBeAddedAbove && !canBeAddedBelow) {
        // Place the datepicker above input
        this.refDatePicker.style.top = `${triggerRects.top -
          vSpaceGap -
          datePickerRects.height}px`
      }

      if (!canBeAddedAbove && !canBeAddedBelow) {
        // Center the datepicker
        this.refDatePicker.style.top = (viewPortHeight - datePickerRects.height) / 2 + 'px'
      }

      // Position date picker vertical in the center of the trigger element
      this.refDatePicker.style.left = `${triggerRects.left -
        datePickerRects.width / 2 +
        triggerRects.width / 2}px`

      if (
        triggerRects.left -
        datePickerRects.width / 2 +
        triggerRects.width / 2 -
        hSpaceGap <=
        0
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the left of the screen
        this.refDatePicker.style.left = `${hSpaceGap}px`
      } else if (
        triggerRects.left -
        datePickerRects.width / 2 +
        triggerRects.width / 2 +
        datePickerRects.width +
        hSpaceGap >=
        viewPortWidth
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the right of the screen
        this.refDatePicker.style.left = null
        this.refDatePicker.style.right = `${hSpaceGap}px`
      }
    },

    /**
     * Proces dates and time methods
     */
    applyNewDates() {
      if (!this.isLoading) {
        this.setOpeningHours(this.openingHoursData)
        this.$emit('update', this.dateTime)
        this.hideDatePicker()
      }
    },

    async dateOne(val) {
      if (this.datePickerReady) {
        if (this.mode === 'single') {
          this.dateTime.dates = val
          if (!this.confirmChoice) {
            this.applyNewDates()
          } else {
            this.getOpeningHours()
          }
        } else {
          // Is date range
          this.dateTime.dates.start = val
        }
      } else {
        this.readyCounter++
      }
    },

    dateTwo(val) {
      if (this.datePickerReady) {
        this.dateTime.dates.end = val
      } else {
        this.readyCounter++
      }
    },

    /**
     * Get opening hours based on date selection
     */
    async getOpeningHours() {
      let self = this
      this.isLoading = true
      let startDate =
        this.mode === 'single' ? this.dateTime.dates : this.dateTime.dates.start
      let response = await this.getOpeningHoursData(startDate)
      if (response.status === 200) {
        this.openingHoursData = response.data
        let opResponse = await this.processOpeningHours({
          startDate: startDate,
          startMinutes: self.dateTime.startMinutes,
          endMinutes: self.dateTime.endMinutes,
          openingHour: response.data,
        })
        self.dateTime.startMinutes = opResponse.startMinutes
        self.dateTime.endMinutes = opResponse.endMinutes
      }

      this.isLoading = false
    },

    /**
     * Dynamic bindings and listeners for date picker
     */
    buildBindings() {
      let o = {
        minDate: this.minDate,
        'date-one': this.dateTime.dates,
        mode: this.mode,
        inline: true,
        showActionButtons: false,
        showShortcutsMenuTrigger: false,
        closeAfterSelect: false,
        monthsToShow: 1,
        showMonthYearSelect: false,
        yearsForSelect: 4,
      }

      if (this.mode === 'range') {
        o['showActionButtons'] = false
        o['date-one'] = this.dateTime.dates.start
          ; (o['date-two'] = this.dateTime.dates.end), (o['monthsToShow'] = 2)
      }
      return o
    },

    datePickerListeners() {
      let dh = {
        'date-one-selected': this.dateOne,
      }

      if (this.mode === 'range') {
        dh['date-one-selected'] = this.dateOne
        dh['date-two-selected'] = this.dateTwo
        dh['apply'] = this.applyNewDates
        dh['cancelled'] = this.hideDatePicker
      }
      return dh
    },
  },
}
</script>
