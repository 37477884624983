<template>
  <div>
    <div
      :class="`${$classPrefix}title`"
      class="is-4 "
      v-html="$t('Components.NoAuthLoginForm.Main_Title')"
    ></div>
    <div
      class="has-margin-bottom"
      v-html="explanationText"
    ></div>
    <Form
      ref="form"
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <EmailField
        name="email"
        :label="$t('Components.ForgotLoginRegister.General.Label_EmailAddress')"
        required
        :validationRules="emailValidationRules()"
        @input="reInitializeForm"
      />
      <TransitionExpand>
        <div
          v-if="showFullForm"
          class="mb-4"
        >
          <Message>
            {{ $t('Components.ForgotLoginRegister.Register.Message_MissingData') }}
          </Message>

          <RequiredField
            name="firstName"
            :label="$t('Components.ForgotLoginRegister.Register.Label_FirstName')"
            required
          />

          <RequiredField
            name="lastName"
            :label="$t('Components.ForgotLoginRegister.Register.Label_LastName')"
            required
          />

          <!-- profile terms -->
        </div>
      </TransitionExpand>

      <div :class="`${$classPrefix}level is-mobile`">
        <div :class="`${$classPrefix}level-left`"></div>
        <div :class="`${$classPrefix}level-right`">
          <div :class="`${$classPrefix}level-item`">
            <a
              :class="[`${$classPrefix}button`, { 'is-loading': isProcessing }]"
              class="is-success"
              @click="doSubmit"
              v-html="$t('Components.NoAuthLoginForm.Button_Request')"
            >
            </a>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import Form from '@/components/UI/Form/BaseForm'
import RequiredField from '@/components/UI/Form/RequiredField'
import EmailField from '@/components/UI/Form/EmailField'
import Message from '@/components/UI/Message'
import TransitionExpand from '../TransitionExpand.vue'
import profileProvider from '../../providers/profile'

export default {
  name: 'NoAuthLoginForm',

  components: {
    Form,
    RequiredField,
    EmailField,
    Message,
    TransitionExpand
  },

  data() {
    return {
      isProcessing: false,
      emailCheckTimeout: '',
      showFullForm: false,
      email: '',
      timer: null
    }
  },

  computed: {
    ...mapGetters('searchStore', ['ChannelId']),
    ...mapGetters('authStore', ['isLoggedIn']),

    explanationText() {
      let text = ''

      switch (this.ChannelId) {
        case 1:
          break
        case 1031:
          text = this.$t('Components.NoAuthLoginForm.Channel_1031.Text_Explanation')
          break
        default:
          break
      }

      return text
    }
  },

  created() { },

  methods: {
    ...mapActions('authStore', ['fetchProfile']),

    doSubmit() {
      if (this.$refs.form) {
        this.$refs.form.submitForm()
      }
    },

    reInitializeForm() {
      if (this.showFullForm) {
        this.showFullForm = false
        this.email = ''
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$refs.form.runValidation({ silent: true })
          clearTimeout(this.timer)
        }, 50);
      }
    },

    emailValidationRules() {
      return {
        required,
        email
      }
    },

    // Handle form submittion
    async handleSubmit({ formData }) {
      // let self = this
      if (!formData.email) {
        return false
      }

      return new Promise(async (resolve, reject) => {
        // First check if email exists
        // if (formData.email !== this.email) {
        //   let checkMailResponse = await profileProvider.checkEmail({ email: formData.email })
        //   if (checkMailResponse.status === 200 && !checkMailResponse.data && (!formData.firstName || !formData.lastName)) {
        //     this.email = formData.email
        //     this.showFullForm = true
        //     this.timer && clearTimeout(this.timer)
        //     this.timer = setTimeout(() => {
        //       this.$refs.form.runValidation({ silent: false })
        //       clearTimeout(this.timer)
        //     }, 50);
        //     return reject({ response: 'INVALID' })
        //   }
        // }

        // Fetch profile
        return profileProvider.fetchProfile({
          FirstName: formData.firstName ?? '',
          LastName: formData.lastName ?? '',
          Email: formData.email,
          Language: this.$i18n.locale,
          ChannelId: this.ChannelId
        })
          .then(response => {
            this.$emit('callbackAction', {
              authVerificationKey: response.data,
              authEmail: formData.email,
              agreeToTerms: formData.agreeToTerms ?? false
            })
            resolve({ response: 'COMPLETED' })
          })
          .catch(() => {
            this.showFullForm = true
            this.timer && clearTimeout(this.timer)
            this.timer = setTimeout(() => {
              this.$refs.form.runValidation({ silent: false })
              clearTimeout(this.timer)
            }, 50);
            reject({ response: 'INVALID' })
          })
      })
    },

    // Do action for current form status
    onStatusChange({ status, form }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'VALID':
          this.formError = !form.isValid()
          break
        case 'INVALID':
          this.formError = form.isValid()
          this.isProcessing = false
          break
        case 'COMPLETED':
          this.isProcessing = false
          break
      }
    },
  },
}
</script>
