<template>
  <div
    ref="c_lab_bw"
    :class="`${$classPrefix}widget`"
  >
    <SearchWidget
      v-if="!hideMainSearchForm && widgetInitialized"
      class="page-searchWidget"
    />
    <component
      v-if="currentSearch && widgetInitialized && wizardComponentType"
      :key="wizardComponentType"
      :is="wizardComponentType"
      v-bind="wizardComponentProps"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import EventBus from "@/eventbus/EventBus";
import SearchWidget from "@/components/SearchWidget";
import ModalWizard from "@/components/ModalWizard";
import PageWizard from "@/view/PageWizard";

export default {
  name: "cyberdigma_lab-booking-wizard",

  props: {
    widgetKey: {
      type: String,
      default: "",
    },

    /**
     * Widget language
     * - en = English
     * - nl = Dutch
     */
    locale: {
      type: String,
      default: "en",
    },

    /**
     * Set lead source
     */
    source: {
      type: String,
      default: "",
    },

    /**
     * Pre set location ID
     */
    locationId: {
      type: Number,
      default: 0,
    },

    /**
     * Internal generated search reference
     */
    searchKey: {
      type: String,
      default: "",
    },

    /**
     * Determine whether to show the wizard in and a modal or on page.
     */
    showResultInModal: {
      type: Boolean,
      default: true,
    },

    /**
     * Give premission to fire custom event
     */
    fireCustomEventAllowed: {
      type: Boolean,
      default: false,
    },
    preSelectedMeetingType: {
      type: Number,
      default: 0,
    },
  },

  components: {
    SearchWidget,
    ModalWizard,
    PageWizard,
  },

  data() {
    return {
      wizardComponentProps: {},
      wizardComponentType: null,
      showSearch: true,
    };
  },

  computed: {
    ...mapState("searchStore", ["cleanSearchSrc", "search", "currentSearch"]),
    ...mapState("widgetStore", ["widgetInitialized"]),

    hideMainSearchForm() {
      return !this.showResultInModal && this.currentSearch !== null;
    },
  },

  watch: {
    locale: {
      immediate: true,
      handler: function(val) {
        this.$i18n.locale = val;
      },
    },

    "$i18n.locale": {
      immediate: true,
      handler: function(locale) {
        this.setSearchAndCartLanguage(locale);
      },
    },
  },

  async created() {
    await this.init();
    this.getWizardComponent();
  },

  mounted() {
    window.addEventListener("resize", this.getScreenproperties);
    this.getScreenproperties();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getScreenproperties);
  },

  methods: {
    ...mapMutations(["setFireCustomEventAllowed", "setViewportMode"]),
    ...mapMutations("searchStore", [
      "setSearchKey",
      "setSearchLanguage",
      "setShowResultInModal",
      "setSearchDetails",
      "setCurrentSearch",
      "setSource",
    ]),
    ...mapMutations("widgetStore", [
      "setWidgetState",
      "setWidgetLeadSource",
      "setWidgetStateLocationId",
      "setPreSelectedMeetingType",
      "setWidgetInitialized",
    ]),
    ...mapActions("cartStore", ["updateCartLanguage"]),
    ...mapActions("authStore", ["checkUserCookies", "setMachineToken"]),
    ...mapActions("widgetStore", [
      "setWidgetKey",
      "getWidgetState",
      "getOpeningHoursData",
    ]),
    ...mapActions("countryStore", ["getActiveCountries"]),
    ...mapActions("searchStore", ["resetSearch"]),

    async init() {
      /**
       * First reset store objects
       * This is needed when you are placing the widget on multiple pages on a VUE single page application
       * */
      this.setWidgetState(null);
      this.setSearchDetails(
        this.$objectHelper.cleanSource(this.cleanSearchSrc)
      );
      this.setCurrentSearch(null);

      this.setSearchAndCartLanguage(this.$i18n.locale);

      // Set store data with incoming prop data
      this.setWidgetLeadSource(this.source);
      this.setWidgetStateLocationId(this.locationId);
      this.setPreSelectedMeetingType(this.preSelectedMeetingType);
      this.setFireCustomEventAllowed(this.fireCustomEventAllowed);
      this.setWidgetKey(this.widgetKey);

      // Load widget state
      await this.getWidgetState();
      if (!this.locationId) {
        await this.getActiveCountries(this.search.MeetingtypeId);
      }

      // Check if user is already logged in
      await this.checkIfUserCookiesExists();

      // Process widget custom styling
      this.processCustomStyle();

      /**
       * If the URL contains a searkey then update search key in search object
       * TODO: And start direct with searching for available locations
       */
      let arr = window.location.search.substring(1).split("&");
      let searchKeyParam = arr.find((k) => k.indexOf("c_lab_sk") !== -1);
      if (typeof searchKeyParam !== "undefined") {
        // Save key in store
        this.setSearchKey(searchKeyParam.split("=")[1]);
      }
      arr = [];
      searchKeyParam = "";
      this.setSource(this.source);

      // Initialization is ready
      this.setWidgetInitialized(true);
    },

    // Set search and cart language
    setSearchAndCartLanguage(locale) {
      this.setSearchLanguage(locale);
      this.updateCartLanguage(locale);
    },

    async checkIfUserCookiesExists() {
      await this.setMachineToken();
      await this.checkUserCookies();
    },

    processCustomStyle() {
      let customStyle = this.$store.getters["widgetStore/customStyle"];

      if (customStyle) {
        let styleElm = document.createElement("style");
        let styleBody = "";

        if(customStyle.cssVariables) {
          styleBody += `.${this.$classPrefix}widget { ${customStyle.cssVariables} }`
        }

        if (customStyle.defaultText) {
          styleBody += `.${this.$classPrefix}widget, .${this.$classPrefix}widget .${this.$classPrefix}modal, .${this.$classPrefix}widget .${this.$classPrefix}label, .${this.$classPrefix}widget label, .${this.$classPrefix}widget strong, .${this.$classPrefix}widget .${this.$classPrefix}box { ${customStyle.defaultText} }`;
        }
        styleBody += `
        .${this.$classPrefix}widget .${this.$classPrefix
          }button { border-width: ${customStyle.button.borderWidth
          }; border-radius: ${customStyle.button.borderRadius}; }
        .${this.$classPrefix}widget a.${this.$classPrefix
          }button { border-width: ${customStyle.button.borderWidth
          }; border-radius: ${customStyle.button.borderRadius}; }
        .${this.$classPrefix}widget .${this.$classPrefix}button, .${this.$classPrefix
          }widget a.${this.$classPrefix}button, .${this.$classPrefix
          }widget button.${this.$classPrefix}button { ${customStyle?.buttonStyles ? customStyle.buttonStyles : ""
          }}
        .${this.$classPrefix}widget button.${this.$classPrefix
          }button { border-width: ${customStyle.button.borderWidth
          }; border-radius: ${customStyle.button.borderRadius}; }
        .${this.$classPrefix}widget .${this.$classPrefix}button.is-success { ${customStyle.button.isSuccess.state
          } }
        .${this.$classPrefix}widget a.${this.$classPrefix}button.is-success { ${customStyle.button.isSuccess.state
          } }
        .${this.$classPrefix}widget button.${this.$classPrefix
          }button.is-success { ${customStyle.button.isSuccess.state} }
        .${this.$classPrefix}widget .${this.$classPrefix
          }button.is-success[disabled] { ${customStyle.button.isSuccess.disabled
          } }
        .${this.$classPrefix}widget a.${this.$classPrefix
          }button.is-success[disabled] { ${customStyle.button.isSuccess.disabled
          } }
        .${this.$classPrefix}widget button.${this.$classPrefix
          }button.is-success[disabled] { ${customStyle.button.isSuccess.disabled
          } }
        .${this.$classPrefix}widget .${this.$classPrefix
          }button.is-success:hover { ${customStyle.button.isSuccess.hover} }
        .${this.$classPrefix}widget a.${this.$classPrefix
          }button.is-success:hover { ${customStyle.button.isSuccess.hover} }
        .${this.$classPrefix}widget button.${this.$classPrefix
          }button.is-success:hover { ${customStyle.button.isSuccess.hover} }
        .${this.$classPrefix}widget .${this.$classPrefix}input { ${customStyle.input
          } }
        .${this.$classPrefix}widget .${this.$classPrefix
          }input:not(.is-static):hover { ${customStyle.input} }
        .${this.$classPrefix}widget .${this.$classPrefix}select select { ${customStyle.input
          } }
        .${this.$classPrefix}widget .${this.$classPrefix
          }select select:hover { ${customStyle.input} }
        .${this.$classPrefix}widget .${this.$classPrefix}textarea { ${customStyle.input
          } }
        .${this.$classPrefix}widget .${this.$classPrefix}textarea:hover { ${customStyle.input
          } }
        .${this.$classPrefix}widget .has-background-info-light { ${customStyle.frame.backgroundLight
          } }
        .${this.$classPrefix}widget .${this.$classPrefix}message.is-info { ${customStyle.frame.backgroundLight
          } }
        .${this.$classPrefix}widget .${this.$classPrefix}message.is-info .${this.$classPrefix
          }message-body { ${customStyle.frame.borderColor} ${customStyle.frame.text
          } }
        .${this.$classPrefix}widget .has-background-info { ${customStyle.frame.background
          } }
        .${this.$classPrefix}widget .host_contact { ${customStyle.frame.borderColor
          } }
        .${this.$classPrefix}widget .SearchContext { ${customStyle.frame.borderColor
          } }
        .${this.$classPrefix}widget a:not(.button) { ${customStyle?.linkProperties ? customStyle.linkProperties : ""
          } }
        .${this.$classPrefix}widget a:not(.button):hover { ${customStyle?.linkHoverProperties
            ? customStyle.linkHoverProperties
            : ""
          } }
        .${this.$classPrefix}widget a:not(.button).has-text-grey { ${customStyle?.linkDisabled ? customStyle.linkDisabled : ""
          } }
        .${this.$classPrefix}widget .${this.$classPrefix}icon, .${this.$classPrefix
          }widget .searchWidget .c_lab-bw-columns .c_lab-bw-column .${this.$classPrefix
          }icon { ${customStyle?.iconProperty ? customStyle.iconProperty : ""} }
        .${this.$classPrefix}widget .${this.$classPrefix
          }select:not(.is-multiple):not(.is-loading)::after { ${customStyle?.dropdownArrow ? customStyle.dropdownArrow : ""
          } }
        .${this.$classPrefix}widget { ${customStyle?.inputCssVars ? customStyle.inputCssVars : ""
          } }
        `;

        if (customStyle.inputHeight) {
          styleBody += `.${this.$classPrefix}widget .${this.$classPrefix}input,
          .${this.$classPrefix}widget .${this.$classPrefix}button:not(.is-small),
          .${this.$classPrefix}widget .${this.$classPrefix}select:not(.is-multiple, .is-small),
          .${this.$classPrefix}widget .${this.$classPrefix}select:not(.is-multiple, .is-small) select,
          .${this.$classPrefix}widget .${this.$classPrefix}field .${this.$classPrefix}control .${this.$classPrefix}icon
          { ${customStyle.inputHeight} }`;
        }

        if (customStyle.inputFocus) {
          styleBody += `.${this.$classPrefix}widget .${this.$classPrefix}input:not(.is-static):focus,
          .${this.$classPrefix}widget .${this.$classPrefix}textarea:not(.is-static):focus,
          .${this.$classPrefix}widget .${this.$classPrefix}select select:not(.is-static):focus
          { ${customStyle.inputFocus} }`;
        }

        if (customStyle.button.textTransform) {
          styleBody += `
          .${this.$classPrefix}widget .${this.$classPrefix}button { text-transform: ${customStyle.button.textTransform}; }
          .${this.$classPrefix}widget a.${this.$classPrefix}button { text-transform: ${customStyle.button.textTransform}; }
          .${this.$classPrefix}widget button.${this.$classPrefix}button { text-transform: ${customStyle.button.textTransform}; }
          `;
        }
        if (customStyle.button.fontWeight) {
          styleBody += `
          .${this.$classPrefix}widget .${this.$classPrefix}button { font-weight: ${customStyle.button.fontWeight}; }
          .${this.$classPrefix}widget a.${this.$classPrefix}button { font-weight: ${customStyle.button.fontWeight}; }
          .${this.$classPrefix}widget button.${this.$classPrefix}button { font-weight: ${customStyle.button.fontWeight}; }
          `;
        }

        if (customStyle.headers) {
          if (customStyle.headers.font) {
            styleBody += `.${this.$classPrefix}widget .${this.$classPrefix}title { ${customStyle.headers.font} }`;
          }

          if (customStyle.headers.text) {
            styleBody += `.${this.$classPrefix}widget .${this.$classPrefix}title { ${customStyle.headers.text} }`;
          }
        }

        styleElm.append(styleBody);
        this.$refs.c_lab_bw.appendChild(styleElm);
      }
    },

    getScreenproperties() {
      this.setViewportMode(window.innerWidth);

      if (typeof this.$refs.c_lab_bw === "undefined") {
        return;
      }

      let widgetPlaceholder = this.$refs.c_lab_bw.getBoundingClientRect();
      EventBus.$emit("windowResizeListener", {
        window: window,
        widgetPlaceholder: widgetPlaceholder,
      });
      widgetPlaceholder = null;
    },

    getWizardComponent() {
      this.setShowResultInModal(this.showResultInModal);

      if (this.showResultInModal) {
        this.wizardComponentProps = {
          modalSize: "extra-large",
          onCloseFunc: this.hideModalWizard,
          showModal: true,
        };
        this.wizardComponentType = "ModalWizard";
      } else {
        this.wizardComponentProps = {};
        this.wizardComponentType = "PageWizard";
      }
      this.showWizard = true;
    },

    hideModalWizard() {
      this.wizardComponentProps = {};
      this.wizardComponentType = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
